<template>
  <v-container>
    <v-row>
      <v-col
        cols="6"
      >
        <img
          :src="require('../assets/lamiapratica_nero.png')"
          height="30px"
          alt=""
        >
      </v-col>
      <v-col
        cols="6"
      >
        <v-card
          class="mx-auto pa-4 pa-sm-6 pa-md-16 my-4"
          elevation="6"
          style="max-width: 600px;"
        >
          <v-row v-if="step <= 3">
            <v-col class="text-right">
              Step {{ step }} di 3
            </v-col>
          </v-row>
          <v-stepper
            v-model="step"
            flat
            alt-labels
          >
            <v-row>
              <v-col>
                <h2 class="mb-2">
                  {{ header_text }}
                </h2>
              </v-col>
            </v-row>
            <v-stepper-content step="1">
              <v-form
                ref="form"
                class=""
              >
                <v-row class="mt-1">
                  <v-col>
                    <v-text-field
                      v-model="user.email"
                      label="Email"
                      type="email"
                      required
                      :rules="[formRules.required,formRules.emailRules]"
                      dense
                      outlined
                      @keyup.enter="register1"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.password"
                      :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="show1 ? 'text' : 'password'"
                      label="Password"
                      required
                      dense
                      outlined
                      :rules="[formRules.required,formRules.pswRule]"
                      @click:append="show1 = !show1"
                      @keyup.enter="register1"
                      @input="checkPassword"
                      @focus="showPswRules()"
                    >
                    </v-text-field>
                    <ul v-if="pswRulesBox">
                      <li :class="{ is_valid: contains_eight_characters }">
                        Almeno 8 caratteri
                      </li>
                      <li :class="{ is_valid: contains_number }">
                        Almeno un numero
                      </li>
                      <li :class="{ is_valid: contains_uppercase }">
                        Almeno una maiuscola
                      </li>
                      <li :class="{ is_valid: contains_special_character }">
                        Almeno un carattere speciale
                      </li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.password2"
                      :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="show2 ? 'text' : 'password'"
                      label="Conferma Password"
                      required
                      dense
                      outlined
                      :rules="[formRules.required,formRules.pswMatch(user.password2,user.password)]"
                      @click:append="show2 = !show2"
                      @keyup.enter="register1"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-checkbox
                      v-model="user.privacy_setting"
                      dense
                      :rules="[formRules.required]"
                      label="Acconsento al Trattamento dei Dati Personali"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="user.marketing_setting"
                      dense
                      label="Acconsento al Marketing"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="my-1">
                  <v-col>
                    <v-btn
                      block
                      class="primary darken-1 white--text"
                      @click.prevent="register1"
                    >
                      Prosegui
                    </v-btn>
                  </v-col>
                </v-row>
                <v-alert
                  v-if="error"
                  dense
                  outlined
                  color="error"
                  class="my-5"
                  dismissible
                >
                  {{ error }}
                </v-alert>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-form
                ref="form2"
                class=""
              >
                <v-row class="mt-1">
                  <v-col>
                    <v-text-field
                      v-model="user.name"
                      dense
                      :rules="[formRules.required]"
                      label="Nome"
                      required
                      outlined
                      @keyup.enter="register2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.surname"
                      label="Cognome"
                      required
                      dense
                      outlined
                      :rules="[formRules.required]"
                      @keyup.enter="register2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.phone1"
                      type="text"
                      label="Recapito telefonico"
                      required
                      dense
                      outlined
                      :rules="[formRules.required]"
                      @keyup.enter="register2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="my-1">
                  <v-col
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <v-btn
                      block
                      class=""
                      @click="step=1"
                    >
                      Indietro
                    </v-btn>
                  </v-col>
                  <v-col
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <v-btn
                      block
                      class="primary darken-1 white--text"
                      @click.prevent="register2"
                    >
                      Prosegui
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-form
                ref="form3"
                class=""
              >
                <v-row class="mt-1">
                  <v-col>
                    <v-text-field
                      v-model="company.name"
                      dense
                      :rules="[formRules.required]"
                      label="Ragione sociale"
                      required
                      outlined
                      @keyup.enter="register"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-1">
                  <v-col>
                    <v-text-field
                      v-model="company.email"
                      label="Email Aziendale"
                      type="email"
                      required
                      :rules="[formRules.required,formRules.emailRules]"
                      dense
                      outlined
                      @keyup.enter="register"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="company.piva"
                      label="Partita Iva"
                      type="text"
                      required
                      :rules="[formRules.required]"
                      dense
                      outlined
                      @keyup.enter="register"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="my-1">
                  <v-col
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <v-btn
                      block
                      class=""
                      :disabled="loading.register"
                      @click="step=2"
                    >
                      Indietro
                    </v-btn>
                  </v-col>
                  <v-col
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <v-btn
                      block
                      class="primary darken-1 white--text"
                      :loading="loading.register"
                      @click.prevent="register"
                    >
                      Prosegui
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-row>
                <v-col>
                  <p>Complimenti, la registrazione è completa.</p>
                  <p>Una mail è appena stata inviata all'indirizzo email <strong>{{ user.email }}</strong>, verifica la tua posta in arrivo per completare il processo di registrazione.</p>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-card>
        <v-row class="my-4">
          <v-col class="text-center">
            <span>Hai già un account? </span> <v-btn
              class="no-uppercase"
              text
              color="primary"
              :to="{name:'login'}"
            >
              Login
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    user: {},
    company: {},
    error: null,
    show1: false,
    show2: false,
    step: 1,
    loading: {
      register: false,
    },
    pswRulesBox: false,
    errorPasswordMatch: false,
    password_length: 0,
    contains_eight_characters: false,
    contains_number: false,
    contains_uppercase: false,
    contains_special_character: false,
    valid_password: false,
  }),
  computed: {
    ...mapGetters(['formRules']),
    header_text() {
      switch (this.step) {
        case 1:
          return 'Crea il tuo account'
        case 2:
          return 'Informazioni utente'
        case 3:
          return 'La tua azienda'
        default:
          return 'Crea il tuo account'
      }
    },
  },
  methods: {
    showPswRules() {
      this.pswRulesBox = true
    },
    checkPassword() {
      this.password_length = this.user.password.length
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/

      this.contains_eight_characters = this.password_length > 8

      this.contains_number = /\d/.test(this.user.password)
      this.contains_uppercase = /[A-Z]/.test(this.user.password)
      this.contains_special_character = format.test(this.user.password)

      this.valid_password = this.contains_eight_characters === true
        && this.contains_special_character === true
        && this.contains_uppercase === true
        && this.contains_number === true
    },
    register1() {
      if (this.$refs.form.validate()) {
        if (this.user.password !== this.user.password2) {
          this.error = 'Le password devono combaciare'
        } else {
          this.error = null
          this.step = 2
        }
      }
    },
    register2() {
      if (this.$refs.form2.validate()) {
        this.step = 3
      }
    },
    async register() {
      this.loading.register = true
      this.error = null
      if (this.$refs.form3.validate()) {
        this.$store.dispatch('register', this.user).then(async resp3 => {
          if (resp3.data.status === 'success') {
            this.company.user_id = resp3.data.user_id
            const resp = await this.$api.createCompany(this.company)
            if (resp.data.status === 'success') {
              this.step = 4
            }
          } else {
            await this.$store.dispatch('set_alert', { msg: resp3.data.message, type: 'error' })
            this.loading.register = false
          }
        }).catch(err => {
          if (err) {
            this.error = err
          }
          this.loading.register = false
        })
      }
    },
  },
}
</script>
<style scoped>
.is_valid { color: #8BC34A; }
.is_valid:before { width: 100%; }
.no-uppercase {
    text-transform: unset !important;
}
</style>
